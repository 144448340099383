import { Anchor, List, Text, ThemeIcon } from "@mantine/core"
import { IconCheck } from "@tabler/icons-react"
import { PageWrapper } from "./_components"

export const Page = ({ currentPath }: { currentPath: string }) => (
  <PageWrapper currentPath={currentPath}>
    <Text fz="2rem" fw={600} mb="xl">
      The best tax software you've never heard of.
    </Text>
    <FeatureList />
  </PageWrapper>
)

const FeatureList = () => (
  <>
    <List
      spacing="xs"
      mb="xl"
      center
      icon={
        <ThemeIcon variant="white" color="green">
          <IconCheck />
        </ThemeIcon>
      }
    >
      <List.Item>CRA NETFILE Certified</List.Item>
      <List.Item>
        Easy to use, accurate, and{" "}
        <Anchor href="/free" fw={600}>
          free
        </Anchor>
      </List.Item>
      <List.Item>No ads, no distractions, no nonsense</List.Item>
      <List.Item>
        Your data is kept{" "}
        <Anchor href="/privacy" fw={600}>
          private
        </Anchor>{" "}
        and stored locally, not in the cloud
      </List.Item>
      <List.Item>
        Friendly email support from{" "}
        <Anchor href="/about" fw={600}>
          the people who made the product
        </Anchor>
      </List.Item>
    </List>
  </>
)
